.page-title {
  font-size: 3rem;
  text-align: center;
  margin: 3rem;
}

.contact-cards {
  display: flex;
  justify-content: space-evenly;
  padding: 1rem;

  .contact-card {
    // font-size: 1.4rem;
    text-align: center;
    line-height: 2;
    width: 20rem;
    padding: 3rem;
    margin: 1rem;
    // border: solid 2px blue;
    border-radius: 1rem;
    -webkit-box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);
    transition: all 1s;

    &:hover {
      transform: scale(1.1);
      -webkit-box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.2);
    }

    .fas {
      background-color: blue;
      color: #fff;
      width: 4.5rem;
      height: 4.5rem;
      line-height: 4.5rem;
      border-radius: 50%;
      text-align: center;
      vertical-align: middle;
    }

    h2 {
      font-size: 2rem;
    }
  }
}

@media (max-width: 767.98px) {
  .page-title {
    margin: 3rem 3rem 1.5rem 3rem;
  }

  .contact-cards {
    flex-direction: column;

    .contact-card {
      width: initial;
      display: block;
    }
  }
}
