nav {
  top: 0;
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  transition: all 0.4s;
  z-index: 100;

  .moved-logo {
    transform: translateY(250px) translateX(125%) scale(2.4);
  }

  .fa-bars,
  .fa-times {
    display: none;
  }

  a {
    font-weight: bold;
    font-size: 1.2rem;
    height: 3rem;
    color: inherit;

    // Home Link Image
    &:first-of-type {
      display: block;
      height: 70%;

      img {
        height: 100%;
        object-fit: contain;
        transition: all 0.5s;
      }
    }
  }

  ul.main-nav {
    display: flex;
    list-style: none;

    & > li {
      margin: 0 1.5rem;
    }
  }

  .dropdown {
    position: relative;
    font-weight: bold;
    font-size: 1.2rem;
    color: inherit;
    cursor: pointer;

    ul.dropdown-list {
      background: #f4f4f4;
      color: #000;
      display: none;
      position: absolute;
      min-width: 160px;
      list-style: none;

      a {
        font-size: 1rem;
        font-weight: normal;
        padding: 0.6rem;

        &:hover {
          background: #ccc;
        }
      }
    }

    &:hover {
      ul.dropdown-list {
        display: block;
      }
    }
  }

  &.transparent-nav {
    background: transparent;
    color: #fff;

    a {
      color: #fff;
    }
  }
}

@media (max-width: 767.98px) {
  nav {
    position: relative;

    * {
      box-shadow: none !important;
    }

    .moved-logo {
      transform: translateY(250px) translateX(calc(46vw - 50%)) scale(1.75);
    }

    ul.main-nav {
      background: #003087;
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      // height: 100vh;
      width: 80vw;
      padding-top: 5rem;
      flex-direction: column;
      align-items: center;
      // justify-content: center;
      transform: translateX(100%);
      transition: all 0.6s;

      &.showMenu {
        transform: translateX(0);
      }

      & > li {
        margin: 0.75rem 0;
        // padding-left: 1rem;
        // border-bottom: solid 1px #333;
        width: 100%;
        text-align: center;

        a {
          font-weight: normal;
          text-transform: uppercase;
          color: #fff;
        }
      }
    }

    .dropdown {
      position: relative;
      font-weight: normal;
      text-transform: uppercase;
      color: #fff;

      ul.dropdown-list {
        background: rgb(40, 50, 131);
        display: none;
        position: initial;
        list-style: none;
        text-align: left;
        margin-top: 0.75rem;

        a {
          font-size: 0.8rem;
          font-weight: normal;
          text-transform: initial !important;
          padding: 0.6rem;

          &:hover {
            background: #ccc;
          }
        }
      }

      &:hover {
        ul.dropdown-list {
          display: block;
        }
      }
    }

    .fa-bars,
    .fa-times {
      display: inline-block;
      z-index: 101;
    }

    .fa-times {
      color: #fff;
    }
  }
}
