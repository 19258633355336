.modal {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;

  .fa-times {
    display: block;
    text-align: right;
    margin: 0.4rem;
    cursor: pointer;
  }

  .modal-content {
    background: #fff;
    width: 90vw;
    height: 80vh;
  }
}
