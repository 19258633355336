.main-product-container {
  .product-category {
    background: #003087;
    color: #fff;
    padding: 1rem;
  }
  .main-product-display {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 6rem 4rem;

    .image-section {
      order: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      img {
        width: 300px;
        height: 300px;
        object-fit: contain;
      }

      // img::before {
      //   content: '';
      //   position: absolute;
      //   width: 500px;
      //   height: 500px;
      //   background-color: blue;
      //   top: 0;
      //   left: 0;
      //   z-index: 100;
      // }
    }

    .product-info {
      order: 1;
      padding: 4rem;

      h1 {
        font-size: 5rem;
      }

      h5 {
        margin-bottom: 3rem;
      }
    }
  }

  .dimensions {
    background: #191919;
    color: #fff;
    padding: 2rem 8rem;
  }

  .additional-info {
    background: #333;
    color: #fff;
    padding: 4rem 8rem;

    h1 {
      font-size: 3rem;
    }

    .main-title-sub {
      margin-bottom: 1rem;
    }

    h4 {
      font-size: 1.5rem;
    }

    h5 {
      margin-top: 1rem;
      // background: blue;
    }

    p {
      line-height: 1.4;
    }
  }

  .certifications {
    width: 100%;

    a {
      color: inherit;
    }

    .certificates-dropdown-button {
      display: flex;
      justify-content: space-between;
      background: #003087;
      color: #fff;
      padding: 1rem;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 500;
      border: none;
    }

    .certificates-dropdown {
      display: none;

      &.show {
        display: block;
      }
    }

    .certificate {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f8f9fa;
      line-height: 1.5rem;
      letter-spacing: 0.1rem;
      padding: 1rem;
      margin-bottom: 0.5rem;

      strong {
        color: #003087;
        text-transform: uppercase;
      }

      p {
        color: #666;
      }

      .download {
        color: #003087;
      }
    }
  }

  .equipment-and-optionals {
    background: #575756;
    color: #fff;
    padding: 4rem 8rem;
    display: grid;
    grid-template-columns: 1fr 1fr;

    h5 {
      font-size: 1.25rem;
      line-height: 1;
      text-decoration: underline;
    }

    h6 {
      font-size: 1rem;
      margin-top: 1rem !important;
      color: #d7d8de;
    }

    small {
      font-size: 0.8rem;
    }
  }
}

@media (max-width: 991.98px) {
  .main-product-container {
    .main-product-display {
      grid-template-columns: 1fr;

      // .image-section {
      //   order: -1;
      // }
      .product-info {
        order: 3 !important;
        padding: 0 !important;

        h1 {
          font-size: 4rem;
        }
      }
    }
    .dimensions {
      padding: 2rem;
    }

    .additional-info {
      padding: 2rem;
    }

    .equipment-and-optionals {
      grid-template-columns: 1fr;
      padding: 2rem;

      & > div {
        margin-bottom: 2rem;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .main-product-container {
    .main-product-display {
      padding: 2rem !important;
      .product-info {
        padding: 3rem 1rem;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .certifications {
    .certificate {
      .download {
        span {
          display: none;
        }
      }
    }
  }
}
