#products {
  .product-category-title {
    // background: blue;
    // background-image: radial-gradient(
    //   circle farthest-corner at 12.3% 19.3%,
    //   rgba(85, 88, 218, 1) 0%,
    //   rgba(95, 209, 249, 1) 100.2%
    // );
    background: #003087;
    color: #fff;
    padding: 4rem;
    text-align: center;
    font-size: 2.5rem;
    text-transform: capitalize;
  }

  .products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 2rem;
    padding: 6rem;

    .product-tile {
      display: inline-block;
      text-align: center;

      img {
        display: block;
        width: 175px;
        height: 175px;
        object-fit: contain;
        margin: auto;
      }

      h6 {
        color: #003087 !important;
        font-size: 1rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        line-height: 1.2rem;
      }

      small {
        color: #575756;
        font-size: 0.8rem;
      }
    }
  }
}

@media (max-width: 700px) {
  #products {
    .products-grid {
      padding: 3rem 1.5rem;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      grid-gap: 1rem;
      row-gap: 3rem;

      .product-tile {
        img {
          width: 100px;
          height: 100px;
        }

        h2 {
          font-size: 1.4rem;
        }
      }
    }
  }
}
