$primary-color: #003087;

#banner {
  // background: url('https://images.unsplash.com/photo-1561983857-0a48c57dc564?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1047&q=80')
  //   no-repeat center center/cover;
  background: url('../images/coltri-banner_2.jpg') no-repeat center center/cover;
  height: 650px;
}

.product-categories {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-around;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  // max-width: 1300px;
  max-width: 100%;
  margin: 0 2rem;
  background-color: #fff;
  transform: translateY(-50px);
  padding: 1rem 2rem 2rem 2rem;
  border-radius: 20px;
  -webkit-box-shadow: -5px 5px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -5px 5px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: -5px 5px 20px 0px rgba(0, 0, 0, 0.1);
}

.products-category-card {
  display: inline-block;
  width: 150px;
  margin: auto;
  text-align: center;

  &:hover > img {
    transform: translateY(-15px);
  }

  img {
    width: 100%;
    height: 130px;
    object-fit: contain;
    transition: all 1s;
  }

  h6 {
    color: #003087;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    line-height: 1.5;
  }
}

.title-products {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: 2rem;

  .title-line {
    background-color: blue;
    background: linear-gradient(
      90deg,
      rgba(0, 140, 69, 1) 33.3%,
      rgba(244, 245, 240, 1) 33.3%,
      rgba(244, 245, 240, 1) 66.6%,
      rgba(205, 33, 42, 1) 66.6%
    );
    width: 20rem;
    height: 0.4rem;
    margin: auto;
    border-radius: 50px;
  }
}

.section {
  max-width: 100%;
  // overflow-y: scroll;
  padding: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .section-title {
    // position: absolute;
    line-height: 0.9;
    font-size: 9rem;
    font-weight: 900;
    opacity: 0.04;
    z-index: -1;
  }
}

.title-story {
  transform: translateX(-200px);
}

.title-location {
  transform: translateX(130px);
}

.section-story {
  .article-story {
    order: -1;
    max-width: 40rem;
  }
}

.section-location {
  .location-map {
    max-width: 550px;
    height: 412.5px;
  }
}

.homeMap {
  max-width: 550px;
  height: 412.5px;
  margin-left: 10rem;
}

.contact-guidance {
  background-color: rgba(0, 0, 255, 0.2);
  background: url('https://images.unsplash.com/photo-1520758594221-872948699332?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80')
    no-repeat center center/cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  position: relative;

  &:before {
    content: '';
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 100%;
    height: 100%;
  }

  & > * {
    z-index: 1;
  }

  h2 {
    color: #fff;
    font-size: 2.2rem;
    font-weight: 900;
  }
}

@media (max-width: 991.98px) {
  .product-categories {
    grid-template-columns: repeat(3, 1fr);
  }

  .section {
    padding: 0 4rem 4rem 4rem;
    flex-direction: column;
    align-items: initial;
    // justify-content: space-around;

    .section-title {
      line-height: 0.9;
      font-size: 4rem;
      font-weight: 900;
      opacity: 0.3;
      margin-bottom: 1.5rem;
    }
  }

  .title-story,
  .title-location {
    transform: initial;
  }

  .section-story {
    .article-story {
      order: initial;
    }
  }

  .contact-guidance {
    flex-direction: column;
  }
}

@media (max-width: 767.98px) {
  .product-categories {
    grid-template-columns: repeat(2, 1fr) !important;
    padding: 0 0.5rem 2rem 0.5rem;
  }

  .section {
    padding: 2rem !important;
  }

  .contact-guidance {
    padding: 2rem;

    h2 {
      font-size: 2rem;
      margin-bottom: 1.4rem;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .btn {
        margin: 0.5rem;
      }
    }
  }
}
