.manuals-heading {
  background: #003087;
  color: #fff;
  padding: 4rem 0;
  text-align: center;
}

.manuals {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  grid-gap: 1rem;
  padding: 6rem;
  .manual-card {
    width: 275px;
    display: inline-block;
    cursor: pointer;
    // text-align: left;

    img {
      width: 17rem;
      // height: 150px;
      object-fit: contain;
      border: solid 1px #000;
    }

    h6 {
      color: #003087;
      font-size: 1rem;
      font-weight: 500;
      // letter-spacing: 0.1rem;
    }

    small {
      color: #575756;
      font-size: 0.8rem;
    }
  }
}

@media (max-width: 1198.98px) {
  .manuals {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    padding: 2rem;

    .manual-card {
      width: 200px;

      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .manuals {
  }
}

@media (max-width: 767.98px) {
  .manuals {
    grid-template-columns: repeat(2, 1fr);
    padding: 1rem;

    .manual-card {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }
}
