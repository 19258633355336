.whatsapp {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 1rem;
  z-index: 1;

  &-text {
    padding: 0.5rem;
    border-radius: 5px;
    background: #ccc;
    color: #000 !important;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.75px;
  }

  .fa-whatsapp {
    background-color: #25d366;
    color: #fff;
    width: 3.75rem;
    height: 3.75rem;
    line-height: 3.75rem;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
  }
}
