$height: 18rem;

footer {
  font-size: 0.8rem;
  background-color: #aaa;
  color: #555;
  height: $height;
  display: grid;
  grid-template-columns: 1fr 2.2fr;

  #map {
    width: 100%;
    height: $height;
    position: relative;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .info {
    padding: 3rem;
    display: flex;
    justify-content: space-around;
  }

  .footer-navigation {
    h3 {
      margin-bottom: 0.8rem;
    }

    ul {
      list-style: none;
      line-height: 1.6;

      a {
        color: inherit;
      }
    }
  }

  .footer-details {
    line-height: 1.8;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  .fa-facebook {
    margin-top: 1rem;

    &:hover {
      color: #1778f2;
      transition: all 0.3s;
    }
  }

  .copyright {
    margin-top: 1rem;
  }
}

@media (max-width: 767.98px) {
  footer {
    height: initial;
    // grid-template-columns: 1fr;

    #map {
      // height: initial;
      height: 15rem;
    }

    .info {
      // order: -1;
      flex-direction: column;

      & > *:first-child {
        margin-bottom: 2rem;
      }
    }
  }
}

@media (max-width: 991.98px) {
  footer {
    grid-template-columns: 1fr;

    #map {
      height: 10rem;
    }

    .info {
      order: -1;
    }
  }
}
